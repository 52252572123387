import React from "react";
import PropTypes from "prop-types";
import WorkSquare from "../components/WorkSquare";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { addFluidsToArray } from "../libs/fluid-image";

export default class ArtlabPage extends React.Component {
  render() {
    const { data } = this.props;
    let { edges: works } = data.allMarkdownRemark;
    const { edges: allImages } = data.allImageSharp;
    works = addFluidsToArray(works, allImages);
    /*
         {
           "excerpt":"LOREM IPSUM ES SIMPLEMENTE EL TEXTO DE RELLENO DE LAS IMPRENTAS Y ARCHIVOS DE TEXTO. LOREM IPSUM HA SIDO EL TEXTO DE RELLENO ESTÁNDAR DE LAS INDUSTRIAS DESDE EL AÑO 1500, CUANDO UN IMPRESOR (N. DEL T. PERSONA QUE SE DEDICA A LA IMPRENTA) DESCONOCIDO USÓ UNA GALERÍA DE .",
           "id":"/Users/macbookpro/proyectos/work-web-portfolio-mauco-sosa/src/pages/work/2018-08-16-london-spectrum-map.md absPath of file >>> MarkdownRemark",
           "fields":{
             "slug":"/artlab/2018-08-16-london-spectrum-map/"
           },
           "frontmatter":{
             "title":"London Spectrum map",
             "templateKey":"artlab-post",
             "date":"August 16, 2018",
             "thumbnail":"/img/london-spectrum-map.jpg"}
           }
         */

    return (
      <Layout>
        <section className="section projectArtlab">
          <div className="titlePage">
            <h1
              style={{ display: "none" }}
              className="has-text-weight-bold is-size-2"
            >
              Work
            </h1>
          </div>
          <div className="squareWorkFlex">
            {works.map(({ node: work }) => (
              <WorkSquare projectArtlab key={work.fields.slug} work={work} />
            ))}
          </div>
        </section>
      </Layout>
    );
  }
}

ArtlabPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export const pageQuery = graphql`
  query ArtlabQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "artlab-post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            thumbnail
          }
        }
      }
    }
    allImageSharp {
      edges {
        node {
          fluid {
            tracedSVG
            aspectRatio
            src
            srcSet
            sizes
            originalName
          }
        }
      }
    }
  }
`;
